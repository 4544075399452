import { TrashIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { SavingsAccountsTerms } from '@/components/BankCard/parts/SavingsAccountsTerms';
import { useGetDepositJsonLD } from '@/hooks/microdata/useGetDepositJsonLD';
import { getBankLogoURL } from '@/services/supabase';
import { useAuthStore } from '@/store';
import type { IBankDepositCard } from '@/types';
import { cn } from '@/utils';
import { COUNTRIES_PATHS } from '@/utils/countries';
import { getSlug } from '@/utils/getSlug';

import { BadgeDeposit } from '../BadgeDeposit';
import { Icon } from '../Sprite';
import { ButtonsCard, DeletePopup } from './parts';

type BankCardProps = {
  bank: IBankDepositCard;
  className?: string;
  favoritePage?: boolean;
  isFavourite?: boolean;
  isBlogBankCard?: boolean;
  handleFavourite?: (bank: IBankDepositCard) => void;
};

export const SavingsAccountCard: FC<BankCardProps> = ({
  bank,
  className,
  favoritePage = false,
  isFavourite,
  isBlogBankCard,
  handleFavourite,
}) => {
  const { t } = useTranslation();
  const { isTestUser } = useAuthStore((state) => state);
  const { locale } = useRouter();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [src, setSrc] = useState(bank.bankIndex);
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (e.target) {
      setSrc('mockup');
    }
  };

  const handleFavoriteClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (isFavourite) {
      setIsOpenPopup(true);
    } else {
      handleFavourite?.(bank);
    }
  };
  useEffect(() => {
    if (!isFavourite) {
      setIsOpenPopup(false);
    }
  }, [isFavourite]);

  const jsonLd = useGetDepositJsonLD(bank);

  return (
    <div
      className={cn(
        'shadow-far relative flex max-w-full flex-col rounded-xl bg-white',
        className,
      )}
    >
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
      <div className="body3 top-0 -mt-2 ml-[14px] flex flex-wrap gap-1 font-semibold md:absolute md:left-[22px] md:ml-0 md:mt-0 md:-translate-y-1/2 md:gap-2">
        {bank.cashback && bank.cashback !== 'no' && (
          <BadgeDeposit variant="cashback" />
        )}
        {(!bank.annualFee || bank.annualFee === 'free') && (
          <BadgeDeposit variant="free-maintenance" />
        )}
        {bank.isPartnerBank && <BadgeDeposit variant="available" />}
        {bank.depositType === 'subordinated deposit' && (
          <BadgeDeposit variant="subordinated" />
        )}
        {bank.depositType === 'ordinary deposit' && (
          <BadgeDeposit variant="protected" />
        )}
      </div>

      <div
        className={cn(
          'flex-1 px-4 pb-4 pt-[20px] md:px-6 lg:pt-[24px] xl:pb-3 xl:pt-7',
        )}
      >
        <div
          className={cn(
            'flex justify-between',
            isBlogBankCard && 'xl:justify-normal',
          )}
        >
          <div
            className={cn(
              'md:justify-between xl:w-full xl:min-w-[320px] xl:max-w-[370px]',
              isBlogBankCard && 'xl:max-w-[280px] xl:min-w-[280px]',
            )}
          >
            <div className="flex gap-2 md:gap-3">
              {bank.bankSlug ? (
                <Link
                  href={{
                    pathname: `/banks/${getSlug(COUNTRIES_PATHS[bank.bankSlugCountry][locale || 'en'])}/${bank.bankSlug}`,
                    query: isTestUser ? { test_user: isTestUser } : undefined,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Image
                    src={getBankLogoURL(src)}
                    // alt={bank.productIndex.toString()}
                    alt="bankImage"
                    width={64}
                    height={64}
                    loading="lazy"
                    onError={handleImageError}
                    className={cn(
                      'h-6 w-6 rounded-full border bg-blue-900/10 min-[375px]:h-[38px] min-[375px]:w-[38px] md:h-[40px] md:w-[40px] lg:h-[56px] lg:w-[56px] xl:h-[64px] xl:w-[64px]',
                      isBlogBankCard && 'xl:h-14 xl:w-14',
                    )}
                  />
                </Link>
              ) : (
                <Image
                  src={getBankLogoURL(src)}
                  // alt={bank.productIndex.toString()}
                  alt="bankImage"
                  width={64}
                  height={64}
                  loading="lazy"
                  onError={handleImageError}
                  className={cn(
                    'h-6 w-6 rounded-full border bg-blue-900/10 min-[375px]:h-[38px] min-[375px]:w-[38px] md:h-[40px] md:w-[40px] lg:h-[56px] lg:w-[56px] xl:h-[64px] xl:w-[64px]',
                    isBlogBankCard && 'xl:h-14 xl:w-14',
                  )}
                />
              )}
              <div className="flex max-w-sm flex-col leading-tight">
                <div className="flex flex-col text-start text-black">
                  <Link
                    href={`/deposits/${bank.productIndex}`}
                    className="md:hidden"
                  >
                    <p
                      className={cn(
                        'line-clamp-2 min-w-[140px] max-w-[140px] text-[15px] font-bold capitalize leading-[20px] tracking-[0.2px] text-blue-900 md:line-clamp-none md:min-w-[200px] md:max-w-[200px] xl:min-w-[260px] xl:max-w-[260px] xl:text-[18px] xl:font-semibold xl:leading-[24px] xl:tracking-[0.1px]',
                        isBlogBankCard &&
                          'md:min-w-[180px] md:max-w-[180px] lg:min-w-[200px] lg:max-w-[200px] xl:min-w-[220px] xl:max-w-[220px]',
                      )}
                    >
                      {bank.bankName}
                    </p>
                  </Link>
                  <p
                    className={cn(
                      'line-clamp-2 hidden md:block min-w-[140px] max-w-[140px] text-[15px] font-bold capitalize leading-[20px] tracking-[0.2px] text-blue-900 md:line-clamp-none md:min-w-[200px] md:max-w-[200px] xl:min-w-[260px] xl:max-w-[260px] xl:text-[18px] xl:font-semibold xl:leading-[24px] xl:tracking-[0.1px]',
                      isBlogBankCard &&
                        'md:min-w-[180px] md:max-w-[180px] lg:min-w-[200px] lg:max-w-[200px] xl:min-w-[220px] xl:max-w-[220px]',
                    )}
                  >
                    {bank.bankName}
                  </p>
                  <p className="body3 text-grey-600">
                    {t(`database.countries.${bank.country}`)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-4">
            <SavingsAccountsTerms
              isBlogBankCard={isBlogBankCard}
              interestRate={bank.grossInterestRate}
              annualFee={
                bank.annualFee
                  ? t(`savings-accounts.annual-fee.${bank.annualFee}`)
                  : '-'
              }
              cashback={bank.cashback}
              className={cn(
                isBlogBankCard &&
                  'xl:min-w-[83px] xl:max-w-[83px] xl:gap-4 xl:ml-4',
              )}
            />
            <div
              className={cn(
                'hidden gap-2 lg:flex lg:gap-4',
                isBlogBankCard && 'xl:gap-3 xl:pl-[212px]',
              )}
            >
              <ButtonsCard
                bank={bank}
                isPartnerBank={bank.isPartnerBank}
                isBlogBankCard={isBlogBankCard}
              />
              <div className="hidden lg:flex lg:items-start">
                {favoritePage ? (
                  <button
                    type="button"
                    className="border-none"
                    onClick={() => setIsOpenPopup(false)}
                  >
                    <TrashIcon className="h-5 w-6 cursor-pointer text-grayColor" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="cursor-pointer border-none hover:text-pink-500"
                    onClick={handleFavoriteClick}
                  >
                    <Icon
                      name={
                        isFavourite ? 'button-fav-is-active' : 'button-to-fav'
                      }
                      className="size-8"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          {isOpenPopup && (
            <DeletePopup
              onCancel={() => setIsOpenPopup(false)}
              onDeleteFavorite={() => handleFavourite?.(bank)}
            />
          )}
        </div>
        <div
          className={cn(
            'mt-3',
            'flex flex-col ml-12 md:ml-[52px] xl:ml-[78px] border-blue-100 lg:ml-[54px]',
            !bank.comment && 'md:border-none lg:border-none',
          )}
        >
          <div className="flex justify-between border-y border-blue-100 pb-[12px] pt-[10px] md:hidden">
            <p className="body3 text-grey-600">{t('global.annual_fee')}</p>
            <p className="body3-bold text-blue-900">
              {bank.annualFee
                ? t(`savings-accounts.annual-fee.${bank.annualFee}`)
                : '-'}
            </p>
          </div>
          <div className="flex justify-between pb-[12px] pt-[10px] md:hidden">
            <p className="body3 text-grey-600">{t('global.cashback')}</p>
            <p className="body3-bold text-blue-900">
              {bank.cashback
                ? t(`savings-accounts.cashback.${bank.cashback}`)
                : t(`savings-accounts.cashback.no`)}
            </p>
          </div>
          {bank.comment &&
            bank.comment.split('\n').map((item, index) => (
              <div
                className="relative flex items-center border-t border-blue-100 pb-[12px] pt-[10px]"
                key={index}
              >
                <span className="absolute -left-3 size-1 rounded-full bg-blue-500" />
                <p className="body3 w-full font-normal tracking-[0.1px]">
                  {item}
                </p>
              </div>
            ))}
          <div
            className={cn('lg:hidden flex items-center justify-end gap-2 mt-3')}
          >
            <ButtonsCard
              bank={bank}
              isPartnerBank={bank.isPartnerBank}
              isBlogBankCard={isBlogBankCard}
              className="w-full sm:w-auto"
              buttonClassName="w-full sm:w-auto"
            />
            <div className="flex items-center">
              {favoritePage ? (
                <button
                  type="button"
                  className="border-none"
                  onClick={() => setIsOpenPopup(false)}
                >
                  <TrashIcon className="h-5 w-6 cursor-pointer text-grayColor" />
                </button>
              ) : (
                <button
                  type="button"
                  className="cursor-pointer border-none hover:text-pink-500"
                  onClick={handleFavoriteClick}
                >
                  <Icon
                    name={
                      isFavourite ? 'button-fav-is-active' : 'button-to-fav'
                    }
                    className="size-8"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
