import type { FC, ReactNode } from 'react';

import { cn } from '@/utils';

export interface ITableRowProps {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

export const TableRow: FC<ITableRowProps> = ({
  className,
  children,
  onClick,
}) => {
  return (
    <tr
      className={cn(
        'border-b-[1px] first:border-t-[1px] border-gray-200',
        'relative grid grid-cols-[minmax(80px,1fr)_minmax(88px,1fr)_minmax(88px,1fr)_24px] grid-rows-2 py-4 gap-y-1',
        'md:table-row md:first:border-t-0 md:py-0',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};
