import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { useAuthModalsControl } from '@/store';

export const AccountBlock: FC = () => {
  const { t } = useTranslation();
  const { openLoginModal } = useAuthModalsControl((state) => state);

  return (
    <div className="mt-4 flex justify-center text-[15px] font-medium leading-[20px]">
      <span className=" text-blue-800">{t('signup.alredy_have_account')}</span>
      <button
        type="button"
        className="ml-1 text-blue-500"
        onClick={() => openLoginModal()}
      >
        {t('header.login')}
      </button>
    </div>
  );
};
