export const getFreedom24DirectUrl = (lang: string) => {
  switch (lang) {
    case 'nl':
      return 'https://lp.freedom24.com/nl/savings-plans?utm_source=2585&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2585&s_id=click_id';
    case 'fr':
      return 'https://lp.freedom24.com/fr/savings-plans?utm_source=2586&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2586&s_id=click_id';
    case 'it':
      return 'https://lp.freedom24.com/fr/savings-plans?utm_source=2586&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2586&s_id=click_id';
    case 'es':
      return 'https://lp.freedom24.com/es/savings-plans?utm_source=2588&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2588&s_id=click_id';
    case 'de':
      return 'https://lp.freedom24.com/de/savings-plans?utm_source=2589&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2589&s_id=click_id';
    case 'el':
      return 'https://lp.freedom24.com/el/savings-plans?utm_source=2590&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2590&s_id=click_id';
    case 'pt':
      return 'https://lp.freedom24.com/pt/savings-plans?utm_source=2591&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2591&s_id=click_id';
    default:
      return 'https://lp.freedom24.com/en/savings-plans?utm_source=2584&utm_medium=affiliates&utm_campaign=mc_inaff_1499&utm_term=2584&s_id=click_id';
  }
};
