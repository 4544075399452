import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import { cn } from '@/utils';

export interface IMainTitleProps {
  className?: string;
  title: string;
}

export const MainTitle: FC<IMainTitleProps> = ({ className, title }) => {
  const { t } = useTranslation();

  return (
    <h2
      className={cn(
        'text-center text-[32px] font-bold leading-[40px] text-blue-900',
        'md:text-[40px] md:leading-[48px] md:tracking-[-0.64px]',
        className,
      )}
    >
      {t(title)}
    </h2>
  );
};
