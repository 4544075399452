import type {
  ApplicationResponseDto,
  ApplicationStatusEnum,
  ConsentTypesEnum,
  ProfileAttributesDto,
} from '@pickthebank/api-sdk';
import {
  ApiService,
  DepositStatusesEnum,
  GetApplicationRequest,
  GetBanksListRequest,
  GetComplianceVerificationRequest,
  GetComplianceVerificationStatusRequest,
  GetCurrentCountryRequest,
  GetDepositRequest,
  GetUserApplicationsRequest,
  GetUserDepositsReportRequest,
  GetUserProductAvailabilityRequest,
  GetUserProfileAttributesRequest,
  GetUserProfileRequest,
  PostApplicationApplyRequest,
  PostApplicationConfirmRequest,
  PostComplianceVerificationActionRequest,
  PostComplianceVerificationVerifyRequest,
  PutUserProfileAttributesRequest,
  PutUserProfileRequest,
} from '@pickthebank/api-sdk';
import { PostApplicationCancelRequest } from '@pickthebank/api-sdk/request/application/post-application-cancel.request';
import { GetBankRequest } from '@pickthebank/api-sdk/request/bank/get-bank.request';
import { GetRelatedConsentRequest } from '@pickthebank/api-sdk/request/public/consent/get-related-consent.request';
import { GetProductDepositRequest } from '@pickthebank/api-sdk/request/public/product/get-product-deposit.request';
import { GetProductDepositsListRequest } from '@pickthebank/api-sdk/request/public/product/get-product-deposits-list.request';
import { GetSimilarProductDepositsListRequest } from '@pickthebank/api-sdk/request/public/product/get-similar-product-deposits-list.request';
import { GetCountryCitiesRequest } from '@pickthebank/api-sdk/request/public/reference/reliance/get-country-cities.request';
import { GetUserDepositRequest } from '@pickthebank/api-sdk/request/user/deposit/get-user-deposit.request';
import { GetUserDepositsListRequest } from '@pickthebank/api-sdk/request/user/deposit/get-user-deposits-list.request';
import type { GetBanksRequestDto } from '@pickthebank/api-sdk/type/request/public/bank/get-product-deposits-request.dto';
import type { GetProductDepositsRequestDto } from '@pickthebank/api-sdk/type/request/public/product/get-product-deposits-request.dto';
import type { PutProfileDto } from '@pickthebank/api-sdk/type/request/user/put-profile.dto';

class ApiSingleton {
  private static instance: ApiSingleton;

  private apiService: ApiService;

  public constructor() {
    this.apiService = new ApiService();
  }

  public static getInstance(): ApiSingleton {
    if (!ApiSingleton.instance) {
      ApiSingleton.instance = new ApiSingleton();
    }
    return ApiSingleton.instance;
  }

  public setToken(token: string): void {
    this.apiService.setUser({ token });
  }

  public setErrorHandler(errorHandler: (error: any) => void): void {
    this.apiService.setErrorHandler(errorHandler);
  }

  public fetchOneDeposit = (productIndex: number, language: string) => {
    return this.apiService.send(new GetDepositRequest(productIndex, language));
  };

  public getUserProfile = () => {
    const request = new GetUserProfileRequest();
    return this.apiService.send(request);
  };

  public getUserProfileAttributes = () => {
    const request = new GetUserProfileAttributesRequest();
    // eslint-disable-next-line consistent-return
    return this.apiService.send(request);
  };

  public postApplicationApplyRequest = (
    language: string,
    productIndex: number,
  ): Promise<ApplicationResponseDto> => {
    const request = new PostApplicationApplyRequest({
      language,
      product_index: productIndex,
    });
    return this.apiService.send(request);
  };

  public postApplicationCancelRequest = (uuid: string): Promise<void> => {
    const request = new PostApplicationCancelRequest(uuid);
    return this.apiService.send(request);
  };

  public getApplicationRequest = (
    uuid: string,
  ): Promise<ApplicationResponseDto> => {
    const request = new GetApplicationRequest(uuid);
    return this.apiService.send(request);
  };

  public getUserApplications = (
    status?: ApplicationStatusEnum[],
  ): Promise<ApplicationResponseDto[]> => {
    const request = new GetUserApplicationsRequest(status);
    return this.apiService.send(request);
  };

  public postApplicationConfirmRequest = (
    uuid: string,
    consents: any[],
    amount: number,
    verifications: any[],
  ): Promise<ApplicationResponseDto> => {
    const payloadConsents = consents.map((c) => {
      return {
        uuid: c.uuid,
        accepted: c.is_signed,
      };
    });
    const request = new PostApplicationConfirmRequest(uuid, {
      amount,
      confirm: {
        consents: payloadConsents,
        verifications,
      },
    });
    return this.apiService.send(request);
  };

  public fetchComplianceVerificationStatus = async () => {
    const request = new GetComplianceVerificationStatusRequest();
    return this.apiService.send(request);
  };

  public fetchComplianceVerification = async (verificationId: string) => {
    const request = new GetComplianceVerificationRequest(verificationId);
    return this.apiService.send(request);
  };

  public postComplianceVerificationAction = async (
    verificationUuid: string,
  ) => {
    const request = new PostComplianceVerificationActionRequest(
      verificationUuid,
    );
    return this.apiService.send(request);
  };

  public postComplianceVerificationVerify = async (
    verificationUuid: string,
    payload: any,
  ) => {
    const request = new PostComplianceVerificationVerifyRequest(
      verificationUuid,
      payload,
    );
    return this.apiService.send(request);
  };

  public putProfile = (data: PutProfileDto) => {
    const request = new PutUserProfileRequest(data);
    return this.apiService.send(request);
  };

  public putUserProfileAttributes = (data: ProfileAttributesDto) => {
    const request = new PutUserProfileAttributesRequest(data);
    return this.apiService.send(request);
  };

  public getBankRequest = (language: string, slug: string, isTest: boolean) => {
    const request = new GetBankRequest({ slug }, language, isTest);
    return this.apiService.send(request);
  };

  public getDepositsListRequest = (
    language: string,
    dto: GetProductDepositsRequestDto,
  ) => {
    const request = new GetProductDepositsListRequest(language, dto);
    return this.apiService.send(request);
  };

  public getSimilarDepositsAvailableOnline = (
    language: string,
    productUuid: string,
    page: number,
    isTestUser: boolean | undefined,
  ) => {
    const request = new GetSimilarProductDepositsListRequest(
      language,
      productUuid,
      page,
      isTestUser || false,
    );
    return this.apiService.send(request);
  };

  public getProductDeposit = (
    language: string,
    index: number,
    isTestUser: boolean | undefined,
  ) => {
    const request = new GetProductDepositRequest(language, {
      index,
      is_test: isTestUser || false,
    });
    return this.apiService.send(request);
  };

  public getUserCountry = () => {
    const request = new GetCurrentCountryRequest();
    return this.apiService.send(request);
  };

  public getBanksListRequest = (language: string, dto: GetBanksRequestDto) => {
    const request = new GetBanksListRequest(language, dto);
    return this.apiService.send(request);
  };

  public getUserDeposits = (
    page: number,
    statuses = [DepositStatusesEnum.OPENED],
  ) => {
    const request = new GetUserDepositsListRequest(page, statuses);
    return this.apiService.send(request);
  };

  public getUserDeposit = (uuid: string) => {
    const request = new GetUserDepositRequest(uuid);
    return this.apiService.send(request);
  };

  public getUserDepositsReport = () => {
    return this.apiService.send(new GetUserDepositsReportRequest());
  };

  public getUserProductAvailabilityRequest = (
    productIndex: string | number,
    withErrorHandler = true,
  ) => {
    return this.apiService.send(
      new GetUserProductAvailabilityRequest(productIndex as number),
      withErrorHandler,
    );
  };

  public getProductConsent = ({
    uuid,
    type,
  }: {
    uuid: string;
    type: ConsentTypesEnum;
  }) => {
    return this.apiService.send(
      new GetRelatedConsentRequest(uuid, type),
      false,
    );
  };

  public getCountryCities = (countryCode: string) => {
    return this.apiService.send(new GetCountryCitiesRequest(countryCode));
  };
}

export default ApiSingleton;
