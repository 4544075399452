/* eslint-disable jsx-a11y/alt-text */
import Image from 'next/image';
import React from 'react';

import bluorPng2x from '@/public/assets/partherBanks/bluor@2x-min.webp';
import lidionPng2x from '@/public/assets/partherBanks/lidion@2x-min.webp';
import saldoPng2x from '@/public/assets/partherBanks/saldo@2x-min.webp';

export const BankLogos = () => {
  return (
    <>
      <Image
        src={saldoPng2x}
        alt="Saldo Logo"
        className="h-[24px] w-[65px] md:h-[32px] md:w-[87px] lg:h-[38px] lg:w-[103px]"
        width={saldoPng2x.width}
        height={saldoPng2x.height}
        loading="lazy"
      />
      <Image
        src={bluorPng2x}
        alt="Bluor Logo"
        className="h-[24px] w-[119px] md:h-[32px] md:w-[136px] lg:h-[38px] lg:w-[162px]"
        width={bluorPng2x.width}
        height={bluorPng2x.height}
        loading="lazy"
      />
      <Image
        src={lidionPng2x}
        alt="Lidion Logo"
        className="h-[24px] w-[66px] md:h-[32px] md:w-[87px] md:last:mr-[54px] lg:h-[38px] lg:w-[104px] lg:last:mr-16"
        width={lidionPng2x.width}
        height={lidionPng2x.height}
        loading="lazy"
      />
    </>
  );
};
