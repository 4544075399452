export function getCountryFlagURL(country: string): string {
  // Normalize the country name by replacing spaces with underscores and capitalizing each word
  // const normalizedCountry = country.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join('_');

  return `https://zqtuokvwrauqlmgvpmty.supabase.co/storage/v1/object/public/flags/${country}.jpg`;
}

export function getBankLogoURL(bankIndex: string): string {
  return `http://balancer/storage/public/banks-logo/${bankIndex}.jpg`;
}
