import type { E164Number } from 'libphonenumber-js';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React from 'react';

import { Button } from '@/components';
import { PhoneInput } from '@/components/Utils/PhoneInput';
import confirmEmailPng2x from '@/public/assets/pics/confirmEmail@2x-min.png';
import confirmEmailWebp2x from '@/public/assets/pics/confirmEmail@2x-min.webp';
import confirmEmailPng1x from '@/public/assets/pics/confirmEmail-min.png';
import confirmEmailWebp1x from '@/public/assets/pics/confirmEmail-min.webp';
import { useSMSConfirmationStore } from '@/store/smsConfirmationStore';

export interface PhoneInputModalProps {
  onSuccess: () => void;
  onSubmit: (phone: string) => Promise<any>;
}
export const PhoneInputModal: FC<PhoneInputModalProps> = ({
  onSuccess,
  onSubmit,
}) => {
  const { setLastSentPhoneNumber, phoneNumber, setPhoneNumber } =
    useSMSConfirmationStore((state) => state);

  const { t } = useTranslation();

  return (
    <div className="flex w-[452px] flex-col items-center text-center">
      <picture>
        <source
          srcSet={`${confirmEmailWebp1x.src} 1x, ${confirmEmailWebp2x.src} 2x`}
          type="image/webp"
        />
        <source
          srcSet={`${confirmEmailPng1x.src} 1x, ${confirmEmailPng2x.src} 2x`}
          type="image/png"
        />
        <img
          src={confirmEmailPng1x.src}
          alt="confirmEmail"
          width={confirmEmailPng1x.width}
          height={confirmEmailPng1x.height}
        />
      </picture>
      <p className="body1-bold mt-4 text-blue-900">
        Text your phone number to verify your account
      </p>
      <PhoneInput
        placeholder={t('compliance.address.phone_number')}
        value={phoneNumber as E164Number}
        onChange={setPhoneNumber}
        type="tel"
        autoComplete="tel"
        className="mt-6"
      />
      <Button
        color="blue"
        className="mt-6 w-full py-[18px]"
        props={{
          disabled: !phoneNumber,
          onClick: async () => {
            // eslint-disable-next-line no-useless-catch
            try {
              await onSubmit(phoneNumber?.split('+')[1] as string);
              setLastSentPhoneNumber(phoneNumber?.split('+')[1] as string);
              onSuccess();
            } catch (e) {
              throw e;
            }
          },
        }}
        // loading={isLoading}
      >
        {t('compliance.verification_status.action')}
      </Button>
    </div>
  );
};
