import {
  EmploymentStatusUserAttributeEnum,
  UserGender,
} from '@pickthebank/api-sdk/index';
import type { TFunction } from 'i18next';

export enum VerificationStatusEnum {
  IDLE = 'idle',
  VERIFIED = 'verified',
  DECLINED = 'declined',
}

// TODO: remove
export const DAYS = Array.from({ length: 31 }, (_, i) => i + 1);
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const YEARS = Array.from(
  { length: 100 },
  (_, i) => new Date().getFullYear() - 18 - i,
);

export const MARITAL_STATUS_OPTIONS = [
  { translationKey: 'compliance.marital_options.single', value: 'single' },
  { translationKey: 'compliance.marital_options.married', value: 'married' },
  { translationKey: 'compliance.marital_options.divorced', value: 'divorced' },
  {
    translationKey: 'compliance.marital_options.separated',
    value: 'separated',
  },
  { translationKey: 'compliance.marital_options.widowed', value: 'widowed' },
  {
    translationKey: 'compliance.marital_options.partnership',
    value: 'registered_partnership',
  },
];

export const getPreparedEmploymentStatuses = (
  t: TFunction<'translation', undefined>,
) => {
  return Object.values(EmploymentStatusUserAttributeEnum).map((status) => {
    return {
      title: t(`compliance.employment_status.${status}`),
      value: status,
    };
  });
};

export const getPreparedGenders = (t: TFunction<'translation', undefined>) => {
  return Object.values(UserGender).map((status) => {
    return {
      title: t(`compliance.genders.${status}`),
      value: status,
    };
  });
};

export const US_TAX_RESIDENCY_OPTIONS = [
  { translationKey: 'global.yes', value: true },
  { translationKey: 'global.no', value: false },
];

export const prepareComplianceEntities = (data: any[]) => {
  const convertedObject: Record<string, any> = {};
  data.forEach((item: any) => {
    switch (item.type) {
      case 'enum':
        convertedObject[item.name] = {
          value: convertedObject[item.name]?.value
            ? [...convertedObject[item.name].value, item.value]
            : [item.value],
          conflict: item.conflict,
        };
        break;
      case 'country':
        convertedObject[item.name] = {
          title: item.value.name,
          value: item.value.alpha2,
          prefix: item.value.alpha2?.toLowerCase(),
          conflict: item.conflict,
        };
        break;
      default:
        convertedObject[item.name] = {
          value: item.value,
          conflict: item.conflict,
        };
        break;
    }
  });
  return convertedObject;
};

export interface CountryAttribute {
  title: string;
  value: string;
  prefix: string;
}

export const prepareUserAttributes = (data: any[]) => {
  const convertedObject: Record<string, any> = {};
  data.forEach((item: any) => {
    switch (item.type) {
      case 'enum':
        convertedObject[item.name] = {
          value: convertedObject[item.name]?.value
            ? [...convertedObject[item.name].value, item.value]
            : [item.value],
          conflict: item.conflict,
        };
        break;
      case 'country':
        convertedObject[item.name] = {
          title: item.value.name,
          value: item.value.alpha2,
          prefix: item.value.alpha2?.toLowerCase(),
        };
        break;
      default:
        convertedObject[item.name] = item.value;
        break;
    }
  });
  return convertedObject;
};
