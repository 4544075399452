import 'react-phone-number-input/style.css';

import type { CountryCode, E164Number } from 'libphonenumber-js';
import type { InputHTMLAttributes } from 'react';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import PhoneInputWithCountrySelect from 'react-phone-number-input';

import { useCmsContent } from '@/contexts/CmsContentContext';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  placeholder: string;
  value: string;
  onChange: (newValue: E164Number) => void;
  type?: string;
  className?: string;
  prefix?: string;
  disabled?: boolean;
  errorText?: string;
}

const CustomInput = forwardRef((props: any, ref) => {
  const inputStyles = useMemo(() => {
    if (props.disabled) {
      return 'cursor-not-allowed text-grey-600';
    }
    return 'cursor-text text-blue-900';
  }, [props.disabled]);

  return (
    <input
      ref={ref}
      value={props.displayValue}
      type={props.type}
      className={`h-[20px] w-full border-transparent bg-transparent text-[15px] font-medium outline-none placeholder:text-grey-800 focus:border-transparent focus:ring-0 ${inputStyles}`}
      placeholder={!props.focused ? props.placeholder : ''}
      onChange={props.handleChange}
      onFocus={props.handleFocus}
      onBlur={props.handleBlur}
      disabled={props.disabled}
      {...props}
    />
  );
});
CustomInput.displayName = 'CustomInput';

export const PhoneInput: React.FC<InputProps> = ({
  placeholder,
  value,
  onChange,
  type = 'text',
  className,
  prefix = '',
  disabled,
  errorText,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const { exactCountryCode } = useCmsContent();
  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleChange = (val: E164Number) => {
    onChange(val);
  };

  const displayValue = useMemo(() => {
    return prefix ? prefix + value : value;
  }, [prefix, value]);

  const containerStyles = useMemo(() => {
    if (disabled) {
      return 'cursor-not-allowed bg-grey-200 text-grey-500';
    }
    if (errorText) {
      return 'bg-white border-red-500';
    }
    return 'bg-white text-grey-800';
  }, [disabled]);

  useEffect(() => {
    console.log(displayValue);
  }, [displayValue]);

  return (
    <div className={`w-full ${className}`}>
      <div
        className={`relative flex h-[56px] w-full flex-col justify-center gap-0.5 overflow-hidden rounded-xl border-[1.25px] border-grey-200 px-4 text-black placeholder:text-grey-800 focus:border-[#326BFF] focus:ring-0 ${containerStyles}`}
      >
        {displayValue && focused && (
          <span className="text-left text-[11px]">{placeholder}</span>
        )}
        <PhoneInputWithCountrySelect
          onChange={handleChange}
          value={value}
          defaultCountry={exactCountryCode as CountryCode}
          inputComponent={CustomInput}
          international
          withCountryCallingCode
          onFocus={handleFocus}
          onBlur={handleBlur}
          numberInputProps={{
            placeholder,
            value,
            onChange,
            prefix,
            disabled,
            errorText,
            type,
            ...props,
          }}
        />
      </div>
      {errorText && (
        <p className="caption ml-[18px] text-red-500">{errorText}</p>
      )}
    </div>
  );
};
