import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import { type FC, useState } from 'react';

import useFavouriteDeposits from '@/hooks/useFavouriteDeposits';
import { useAuthStore } from '@/store';
import type { IBankDepositCard } from '@/types';
import { cn, transformMonths } from '@/utils';

import { DeletePopup } from '../BankCard/parts';
import { Icon } from '../Sprite';
import { TableCell, TableRow } from './parts';

export interface IOtherDepositTableProps {
  className?: string;
  bankInfo: IBankDepositCard[];
  rightAction?: 'goTo' | 'favorite';
  tableClassName?: string;
}

export const OtherDepositTableV2: FC<IOtherDepositTableProps> = ({
  className,
  bankInfo,
  rightAction = 'favorite',
  tableClassName,
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { handleFavourite, checkIsFavorite } = useFavouriteDeposits();
  const { isTestUser } = useAuthStore((state) => state);

  const [deletePopup, setDeletePopup] = useState(false);

  const getTerm = (bank: IBankDepositCard) => {
    const { value, unit } = transformMonths(+bank.termMonths);
    return `${value} ${t(`deposit_card.${unit?.toLowerCase()}_short`)}`;
  };

  const goToDeposit = (bank: IBankDepositCard) => {
    router.push({
      pathname: `/deposits/${bank.productIndex}`,
      query: isTestUser ? { test_user: isTestUser } : undefined,
    });
  };

  return (
    <div className={className}>
      <table className={cn('mx-auto mt-[7px] w-full', tableClassName)}>
        <thead className="">
          <tr
            className={cn(
              'border-b-[1px] border-gray-200 child:py-3.5 child:text-left child:child:text-[13px] child:font-medium child:leading-[18px] child:tracking-[0.1px] child:text-gray-600 md:child:px-2 lg:child:px-3',
              'child:px-3',
            )}
          >
            <th className="!px-0 !text-grey-600 sm:w-[200px] lg:w-[276px]">
              {t('deposit_card.interest_rate')}
            </th>
            <th className="!px-0 !text-grey-600 sm:w-[200px] lg:w-[276px]">
              <span className="block px-3 sm:px-0">
                {t('deposit_card.term')}
              </span>
            </th>
            <th className="max-w-[70px] !px-0 !text-grey-600 sm:w-[200px] sm:max-w-[unset] lg:w-[263px]">
              <span className="block px-3 sm:px-0">
                {t('global.int_payment_frequency')}
              </span>
            </th>
            <th className="!px-0" />
          </tr>
        </thead>
        <tbody>
          {bankInfo.map((bank, index) => (
            <TableRow
              key={index}
              className="group !table-row cursor-pointer"
              onClick={() => {
                goToDeposit(bank);
              }}
            >
              <TableCell className="min-w-0 shrink-0 !px-0 !py-5  text-2xl font-semibold leading-[28px] tracking-[0.2px] text-blue-900 group-hover:text-blue-500">
                {rightAction === 'favorite' ? (
                  <Link
                    href={{
                      pathname: `/deposits/${bank.productIndex}`,
                      query: isTestUser ? { test_user: isTestUser } : undefined,
                    }}
                  >
                    {Number(bank.grossInterestRate).toFixed(2)}%
                  </Link>
                ) : (
                  <span>{Number(bank.grossInterestRate).toFixed(2)}%</span>
                )}
              </TableCell>

              <TableCell className="truncate !px-0 text-[15px] font-medium leading-[20px] tracking-[0.2px] text-blue-900">
                <span className="block px-3 sm:px-0">{getTerm(bank)}</span>
              </TableCell>

              <TableCell className="col-span-3 !px-0 pl-0 text-[13px] font-medium leading-[18px] tracking-[0.1px] !text-grey-800 md:pl-2 md:text-gray-800 lg:pl-3">
                <span className="block px-3 sm:px-0">
                  {bank.interestPaymentFrequency}
                </span>
              </TableCell>

              <TableCell className="p-2 sm:p-0 md:table-cell">
                <div className="flex items-center justify-end md:gap-6">
                  {rightAction === 'favorite' ? (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (handleFavourite) {
                          handleFavourite(bank);
                        }
                      }}
                      className="relative size-6 border-none bg-transparent text-grey-400 transition-all hover:text-grey-800"
                    >
                      {checkIsFavorite(bank) ? (
                        <Icon name="button-fav-is-active" className="size-6" />
                      ) : (
                        <Icon name="button-to-fav" className="size-6" />
                      )}
                    </button>
                  ) : (
                    <Link
                      href={{
                        pathname: `/deposits/${bank.productIndex}`,
                        query: isTestUser
                          ? { test_user: isTestUser }
                          : undefined,
                      }}
                    >
                      <button
                        type="button"
                        className="relative size-6 border-none bg-transparent text-grey-400 transition-all hover:text-grey-800"
                      >
                        <Icon name="chevron" className="size-6 rotate-90" />
                      </button>
                    </Link>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </table>
      {deletePopup && (
        <DeletePopup
          onCancel={() => setDeletePopup(false)}
          onDeleteFavorite={() => setDeletePopup(false)}
          className="absolute right-[34px] top-[80px]"
        />
      )}
    </div>
  );
};
